import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>This page doesn't exist 😔</p>
  </>
)

export default NotFoundPage
